<template>
  <div v-if="opinionRecordList.length > 0" class="OpinionArea">
    <!-- 报销、付款模块需要在标题右侧新增当前审核人 -->
    <div class="title-container" style="margin-top: 20px">
      <h3>{{ title }}:</h3>
      <div class="instInvolved">{{ instInvolvedStr }}</div>
    </div>
    <div class="text-center">
      <div class="text-center-left" v-for="(i, k) in opinionRecordList" :key="k">
        <el-col :span="12">
          <span :class="['text-btn', `bgc${i.isCheckPass}`]">
            {{ i.isCheckPass | dict(opinionType) }}
          </span>
          <span class="opinion">
            <div>
              步骤:
              <span class="step">{{ i.step }}</span>
            </div>
            <div>
              <span class="conductor">
                处理人:
                <span>{{ i.auditPostName }}-{{ i.auditUserName }}</span>
              </span>

              <span>{{ i.auditDate | dateFormat }}</span>
            </div>
          </span>
        </el-col>
        <el-col :span="12" class="center-right">审批意见: {{ i.opinion }}</el-col>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: [String, Number],
      default: '',
    },
    procInstId: {
      type: [String, Number],
      default: '',
    },
    title: {
      type: String,
      default: '意见区',
    },
    instInvolved: {
      type: Object,
      default: function () {
        return {}
      },
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
    isPay: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      opinionRecordList: [],
      opinionType: [],
    }
  },
  computed: {
    // 根据instInvolved的值动态拼接内容
    instInvolvedStr() {
      if (!this.instInvolved) return ''
      if (!this.instInvolved.instInvolvedUserName) return ''
      if (this.instInvolved.status == '5') return '当前单据审核不通过，已驳回'
      if (this.instInvolved.status == '0') return '当前单据填报人已撤回'
      if (this.instInvolved.instInvolvedUserName.length == 0) return ''
      if (this.instInvolved.instInvolvedUserName.length == 1)
        return `当前单据由 ${this.instInvolved.instInvolvedUserName[0]} 审核中`
      if (this.instInvolved.instInvolvedUserName.length > 1) {
        let str = this.instInvolved.instInvolvedUserName.join(' , ')
        return `当前单据由 ${str} 审核中（其中一人审核即可）`
      }
    },
  },
  created() {},
  filters: {
    dateFormat(time3) {
      //将时间戳格式转换成年月日时分秒
      var date = new Date(time3)
      var Y = date.getFullYear() + '-'
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
      var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '

      var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
      var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
      var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      var strDate = Y + M + D + h + m + s
      return strDate
    },
  },
  watch: {
    id: {
      immediate: true,
      handler: function (id) {
        if (id) {
          Promise.all([
            this.$api.productionControl.opinionRecordList({
              professionalId: id,
            }),
            this.$api.dict.listSysDictData('OPINION_RECORD_CHECK', true),
          ])
            .then(r => {
              this.opinionRecordList = r[0].data
              this.opinionType = r[1].data
            })
            .catch(err => {})
        } else if (this.options.type) {
          Promise.all([
            this.$api.productionControl.opinionRecordList(this.options),
            this.$api.dict.listSysDictData('OPINION_RECORD_CHECK', true),
          ])
            .then(r => {
              this.opinionRecordList = r[0].data
              this.opinionType = r[1].data
            })
            .catch(err => {})
        }
      },
    },
    procInstId: {
      immediate: true,
      handler: function (id) {
        if (id) {
          Promise.all([
            this.$api.productionControl.opinionRecordNewList({
              procInstId: id,
            }),
            this.$api.dict.listSysDictData('OPINION_RECORD_CHECK', true),
          ])
            .then(r => {
              
              this.opinionRecordList = r[0].data
              if(this.opinionRecordList.length>0 && 
              ((this.opinionRecordList[0].step=='财务支付' && this.opinionRecordList[0].isCheckPass == 1) 
              || (this.opinionRecordList[0].step=='后补发票' && (this.opinionRecordList[0].isCheckPass == 21 || this.opinionRecordList[0].isCheckPass == 20) ))
              // || (this.opinionRecordList[0].step=='后补发票' && this.opinionRecordList[0].isCheckPass == 21 ))  
              ) {
                this.$emit('update:isPay', true)
              } else {
                this.$emit('update:isPay', false)
              }
              this.opinionType = r[1].data
            })
            .catch(err => {})
        }
      },
    },
  },
  methods: {
    init(id) {},
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';
.title-container {
  display: flex;

  .instInvolved {
    margin-left: 20px;
    color: #1c81fdeb;
    font-size: 16px;
    font-weight: bold;
  }
}
</style>
